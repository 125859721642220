import React, { useState, useEffect } from "react";
import config from "../../../config";
import { StaticQuery, graphql } from "gatsby";
import SocialLinks from "../SocialLinks";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faEnvelope,
  faMapMarker,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";
import "./_contact.scss";
library.add(...[faWhatsapp, faCheck, faPhoneAlt, faEnvelope, faMapMarker]);

const ContactUs = ({
  bgImage=true, name,
  showAddress = true,
  hideContacts = false,
}) => {
  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [values, setValues] = useState({});
  const [message, setMessage] = useState({});
  useEffect(() => {
    message.variant &&
      setTimeout(() => {
        setMessage({});
      }, 10000);
  }, [message]);

  const submitForm = (e) => {
    setClicked(true);
    if (values.email) {
      return;
    }
    e.preventDefault();

    fetch(`${config.url}${config.pathPrefix}.netlify/functions/eResponse/`, {
      method: "POST",
      body: JSON.stringify({ ...values, from: "message" }),
    })
      .then((data) => {
        setSuccess(true);
        setClicked(false);
        setMessage({
          msg: `A sua mensagem foi enviada com sucesso.`,
          variant: "success",
        });
      })
      .catch((i) => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        setMessage({
          msg: `opss, isto não era o que estavamos à espera... mas este tipo de coisas às vezes acontece :) `,
          variant: "error",
        });
      });
  };
  return (
    <StaticQuery
      query={graphql`
        query {
          wpPage(isFrontPage: { eq: true }) {
            id
            layout {
              whatsapp
              phone
              email
              address
            }
          }
        }
      `}
      render={(data) => (
        <section id={name} className="contact-section contacts-page">
          <main id="main">
            <div className="site-section">
              <div className="container-fluid">
                <div className="row">
                  {!hideContacts && (
                    <Fade bottom>
                      <div
                        className={`col-sm-12 col-md-6 contacts ${
                          bgImage ? "" : "noBg"
                        }`}
                      >
                        <ul className="list-unstyled text-center">
                          {showAddress && (
                            <li className="">
                              <h3 className="contact-title">
                                <FontAwesomeIcon
                                  icon="map-marker"
                                  className="icon"
                                />
                                Onde me encontras?
                              </h3>
                              <div className="container">
                                <div className="row d-flex justify-content-center">
                                  <div
                                    className={`col-md-6 col-sm-12 fg-text-color text-center`}
                                    dangerouslySetInnerHTML={{
                                      __html: data.wpPage.layout.address,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </li>
                          )}
                          {data.wpPage.layout.phone && (
                            <li className="">
                              <div className="contact-title">
                                <FontAwesomeIcon
                                  icon="phone-alt"
                                  className="icon"
                                />
                                {" Vamos Falar?"}
                              </div>
                              <a
                                href={`tel:${data.wpPage.layout.phone.replace(
                                  / /g,
                                  ""
                                )}`}
                              >
                                {data.wpPage.layout.phone}
                              </a>
                            </li>
                          )}
                          {data.wpPage.layout.whatsapp && (
                            <li className="">
                              <div className="contact-title">
                                <FontAwesomeIcon
                                  icon={["fab", "whatsapp"]}
                                  className="icon"
                                />
                                {"Whatsapp"}
                              </div>

                              <a
                                className="contact"
                                href={`https://wa.me/${data.wpPage.layout.whatsapp.replace(
                                  / /g,
                                  ""
                                )}`}
                              >
                                +{data.wpPage.layout.whatsapp}
                              </a>
                            </li>
                          )}
                          {data.wpPage.layout.email && (
                            <li className="">
                              <div className="contact-title">
                                <FontAwesomeIcon
                                  icon="envelope"
                                  className="icon"
                                />
                                {" Dúvidas Gerais"}
                              </div>
                              <a href={`mailto:${data.wpPage.layout.email}`}>
                                {data.wpPage.layout.email}
                              </a>
                            </li>
                          )}
                          <li className="mb-3 socialContactUs fg-text-color">
                            <SocialLinks sectionP={false} />
                          </li>
                        </ul>
                      </div>
                    </Fade>
                  )}
                  <Fade right>
                    <div
                      className={`col-sm-12 fg-bg col-md-${
                        hideContacts ? "12" : "6"
                      } text-center pt-5`}
                    >
                      <h3>Fala Comigo</h3>
                      <form
                        className="contact-form"
                        onSubmit={(e) => submitForm(e)}
                      >
                        <div className="row">
                          <div className="col-md-12 form-group">
                            <label className="text-black" htmlFor="name">
                              Nome
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              required="required"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.currentTarget.name]: e.currentTarget.value,
                                })
                              }
                              placeholder="Como te chamas?"
                            />
                            <div className="validate"></div>
                          </div>
                          <div className="col-md-12 form-group">
                            <label className="text-black" htmlFor="cEmail">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.currentTarget.name]: e.currentTarget.value,
                                })
                              }
                              style={{ display: "none" }}
                            />
                            <input
                              type="email"
                              name="cEmail"
                              required="required"
                              id="cEmail"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.currentTarget.name]: e.currentTarget.value,
                                })
                              }
                              data-rule="cEmail"
                              placeholder="Qual o teu e-mail?"
                            />
                            <div className="validate"></div>
                          </div>
                          <div className="col-md-12 form-group">
                            <label className="text-black" htmlFor="phoneNr">
                              Telefone
                            </label>
                            <input
                              type="tel"
                              name="phoneNr"
                              id="phoneNr"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.currentTarget.name]: e.currentTarget.value,
                                })
                              }
                              data-rule="phone"
                              placeholder="Deixa-me o teu número de telefone"
                            />
                            <div className="validate"></div>
                          </div>
                          <div className="col-md-12 form-group">
                            <label className="text-black" htmlFor="message">
                              Mensagem
                            </label>
                            <textarea
                              name="message"
                              id="message"
                              cols="30"
                              rows="5"
                              required="required"
                              onChange={(e) =>
                                setValues({
                                  ...values,
                                  [e.currentTarget.name]: e.currentTarget.value,
                                })
                              }
                              placeholder="Fala comigo, escreve aqui."
                            ></textarea>
                            <div className="validate"></div>
                          </div>

                          <div className="col-md-12 text-left">
                            <label
                              htmlFor="accept-terms"
                              className="text-black"
                            >
                              <input
                                required
                                type="checkbox"
                                name="accept-terms"
                                id="accept-terms"
                                className="mr-3"
                              />
                              Li e aceito a{" "}
                              <a href="./privacidade" target="_blank">
                                politica de privacidade
                              </a>
                            </label>
                          </div>
                          <div className="col-md-12 form-group">
                            <button
                              type="submit"
                              disabled={success}
                              className={`btn button-em primary-bg`}
                            >
                              {clicked ? (
                                <>
                                  <span className="lds-dual-ring" /> O carteiro
                                  está a chegar
                                </>
                              ) : success ? (
                                <>
                                  <FontAwesomeIcon
                                    icon="check"
                                    className="white"
                                  />{" "}
                                  Mensagem Enviada
                                </>
                              ) : (
                                <>Enviar</>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </main>
          <div className={`snackbar ${message.variant ? message.variant : ""}`}>
            {message.msg}
          </div>
        </section>
      )}
    />
  );
};

export default ContactUs;
