import React from "react";
import Zoom from "react-reveal/Zoom";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

import Header from "../components/Header";
import Loadable from "@loadable/component";
import ContactUs from "../components/ContactUs";
import "../assets/sass/contacts.scss";
const Footer = Loadable(() => import("../components/Footer/index.js"));


const IndexPage = () => {

  return (
    <Layout wrapperClass="contactsWrapper">
      <SEO />
      <Header frontPage={false} selected="contactos" />
      <header className={`contactHeader`}>
        <div className={`container-fluid h-100`}>
          <div className="row d-flex  no-gutters h-100">
            <div className="col-sm-12 col-md-12 d-flex  align-self-center justify-content-center text-center">
              <div>
                <Zoom>
                  <p className="w-100 pb-0 mb-0">
                    Tens alguma questão em que eu possa ajudar?
                  </p>
                  <div className="w-100 pt-0 mt-0 title primary-text-color">
                    Consultas online e/ou presencial
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="contactSection pt-0 pb-0 p-0 pr-0">
        <ContactUs bgImage={false} />
        
      </section>

      <Footer />
    </Layout>
  );
};
export default IndexPage;
